<template>
  <b-row v-if="typeof repetition != 'undefined' && repetition != null">
    <b-col md="4">
      <field-input
          :model.sync="quantityLocal"
          :name="$t('interval')"
          rules="required|integer|min_value:1"
          @input="$emit('updateDisplay', repetition.quantity)"
      />
    </b-col>
    <b-col md="8">
      <field-select
          :model.sync="repetition.period"
          :name="$t('period')"
          :options="periods"
          label="period"
          reduce="value"
          :isRequired="true"
          @input="$emit('updateDisplay')"
      />
    </b-col>

    <b-col md="12"
           v-if="showDays">
      <field-days
          :days.sync="localDays"
          :isRequired="true"
      />
    </b-col>
  </b-row>
</template>

<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { capitalize } from '../../utils/filter'
import { isObject, setRepetitionDisplay } from '../../utils/utils'

import FieldInput from '@/components/input/Input'
import FieldSelect from '@/components/input/Select'
import FieldDays from '@/components/input/Days'
import i18n from '@/libs/i18n'

export default {
  components: {
    FieldInput,
    FieldSelect,
    FieldDays
  },
  props: {
    repetition: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const periods = ref([
      {
        period: capitalize(i18n.t('days')),
        value: 'days'
      },
      {
        period: capitalize(i18n.t('weeks')),
        value: 'weeks'
      },
      {
        period: capitalize(i18n.t('months')),
        value: 'months'
      },
      {
        period: capitalize(i18n.t('years')),
        value: 'years'
      },
    ])

    const quantityLocal = ref(props.repetition.quantity)
    const localDays = ref(props.repetition.days)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const showDays = computed(() => {
      if (
          (
              isObject(props.repetition.period) &&
              props.repetition.period.value == 'weeks'
          ) ||
          props.repetition.period != 'weeks'
      ) {
        props.repetition.days = []
        return false
      } else {
        return true
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(localDays, val => {
      props.repetition.days = val
      setDisplay()
    })

    watch(quantityLocal, val => {
      props.repetition.quantity=val
      emit('updateDisplay')
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const setDisplay = () => {
      if (
          typeof props.repetition != 'undefined' &&
          props.repetition != null &&
          'period' in props.repetition &&
          'quantity' in props.repetition
      ) {

        if (props.repetition.period == 'days') {
          // Days

          if (parseInt(props.repetition.quantity) == 1) {
            props.repetition._display = capitalize(i18n.t('allDays0'))
          } else if (parseInt(props.repetition.quantity) > 1) {
            props.repetition._display = capitalize(i18n.t('allDays', { days: props.repetition.quantity }))
          } else {
            delete props.repetition._display
          }
        } else if (props.repetition.period == 'weeks') {
          // Weeks

          let displayDays = []
          props.repetition.days.forEach(d => {
            displayDays.push(i18n.t(d))
          })
          displayDays = displayDays.join(', ')
          // console.log(displayDays)
          if (parseInt(props.repetition.quantity) == 1) {
            props.repetition._display = capitalize(i18n.t('allWeeks0'))
            props.repetition._display += '<br /><small>' + capitalize(displayDays) + '</small>'
          } else if (parseInt(props.repetition.quantity) > 1) {
            props.repetition._display = capitalize(i18n.t('allWeeks', { weeks: props.repetition.quantity }))
            props.repetition._display += '<br /><small>' + capitalize(displayDays) + '</small>'
          } else {
            delete props.repetition._display
          }

        } else if (props.repetition.period == 'months') {
          if (parseInt(props.repetition.quantity) == 1) {
            props.repetition._display = capitalize(i18n.t('allMonths0'))
          } else if (parseInt(props.repetition.quantity) > 1) {
            props.repetition._display = capitalize(i18n.t('allMonths', { months: props.repetition.quantity }))
          } else {
            delete props.repetition._display
          }
        } else if (props.repetition.period == 'years') {
          if (parseInt(props.repetition.quantity) == 1) {
            props.repetition._display = capitalize(i18n.t('allYears0'))
          } else if (parseInt(props.repetition.quantity) > 1) {
            props.repetition._display = capitalize(i18n.t('allYears', { years: props.repetition.quantity }))
          } else {
            delete props.repetition._display
          }
        }

        emit('updateDisplay')
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      periods,
      quantityLocal,
      localDays,

      // Computed
      showDays,

      // Methods
      setDisplay,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>