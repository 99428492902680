<template>
  <b-row>
    <b-col cols="8">
      <calendar ref="calendar"/>
    </b-col>
    <b-col cols="4">
      <tasks
          :tasks="tasks"
          @updateCalendar="updateCalendar"/>
    </b-col>

    <b-col cols="12">
      <div class="misc-wrapper">
        <b-link class="brand-logo d-flex">
          <b-img
              :src="require('@/assets/images/logo/logo.png')"
              alt="logo"
          />

          <h2 class="brand-text text-primary ml-1">
            ERP
            <small>
              <i>by HMT</i>
            </small>
          </h2>
        </b-link>

        <div class="misc-inner p-2 p-sm-3">
          <div class="w-100 text-center">
            <h2 class="mb-1">
              We are launching soon 🚀
            </h2>
            <p class="mb-3">
              We're creating something awesome. Please subscribe to get notified when it's ready!
            </p>

            <b-img
                fluid
                :src="require('@/assets/images/pages/coming-soon.svg')"
                alt="Coming soon page"
            />
          </div>
        </div>
      </div>
    </b-col>
  </b-row>


</template>

<script>
import { computed, ref, watch, onMounted } from '@vue/composition-api'
import store from '@/store'
import useAPI from '@/utils/useAPI'
import i18n from '@/libs/i18n'

import Calendar from '@/components/home/calendar/Calendar'
import Tasks from '../../components/tasks/Tasks'

export default {
  components: {
    Tasks,
    Calendar,
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const calendar = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { tasks, workflows } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchCalendars,
      fetchTasks,
      fetchWorkflows,
      fetchUsers
    } = useAPI()

    const updateCalendar = () => {
      calendar.value.$refs.calendar.refetchEvents()
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    fetchCalendars()
    fetchTasks()
    fetchWorkflows()
    fetchUsers()

    return {
      // Components

      // Data
      calendar,

      // Computed
      tasks,
      workflows,

      // Methods
      updateCalendar,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>