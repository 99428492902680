<template>
  <b-row>
    <b-col cols="12"
    >
      <div
          @mouseover="taskHover = true"
          @mouseleave="taskHover = false"
          class="d-flex justify-content-between border-bottom-primary mb-2 pb-25"
          :class="taskHover?'border-bottom-primary':'border-bottom'"
          @contextmenu.stop.prevent="$refs.contextMenu.open"
      >

        <div
            @mouseover="radioHover = true"
            @mouseleave="radioHover = false"
            @click="toggleTaskStatus"
            class="cursor-pointer text-primary my-auto pr-1"
        >
          <icon
              v-if="isNew"
              icon="plus"
              size="lg"
          />

          <icon v-else-if="task.isDone"
                size="lg"
                icon="check-circle"/>
          <icon v-else-if="radioHover"
                size="lg"
                icon="check-circle"/>
          <icon v-else
                size="lg"
                library="far"
                icon="circle"/>
        </div>

        <div class="w-100">
          <input v-model="task.title"
                 class="invisibleInput"
                 :placeholder="isNew?$t('AddTask'):''"
                 :class="{'text-decoration-line-through': task.isDone}"
                 @blur="$emit('submitTask')"
                 @keyup.enter="$emit('submitTask')">
          <small>
            <span v-if="task.deadline != null">
              <icon icon="stopwatch"/>
              <i class="pl-50">{{ capitalize($t('deadline:', { deadline: moment(task.deadline).format('L') })) }}</i>
            </span>
            <span
                v-if="task.repetition != null"
                id="tooltip-button-repetition-display"
                class="pl-50"
            >
              <icon icon="sync-alt"/>
            </span>
            <b-tooltip
                v-if="task.repetition != null"
                placement="bottom"
                ref="tooltip"
                target="tooltip-button-repetition-display"
            >
              <span v-html="task.repetition._display" />
            </b-tooltip>

            <!--            <span class="px-50"-->
            <!--                  v-if="task.deadline != null && task.reminder != null">-->
            <!--              &#8226;-->
            <!--            </span>-->

            <!--            <span v-if="task.reminder != null">-->
            <!--              <icon icon="bell"/>-->
            <!--              <i class="pl-25">{{ moment(task.deadline).format('L') }}</i>-->
            <!--            </span>-->
          </small>

        </div>

        <div v-if="!isNew" class="d-flex my-auto">
          <button-view
              @click.native.stop.prevent="$emit('viewMore')"
              :withIcon="true"
              :withText="false"
              size="sm"
              class="mr-50"
          />
          <button-delete
              @click.native.stop.prevent="$emit('delete')"
              :withIcon="true"
              :withText="false"
              size="sm"
          />
        </div>
      </div>


      <vue-context ref="contextMenu">
        <li>
          <b-link @click="$emit('viewMore')">
              <icon icon="eye"/>
              <span class="ml-50">{{ $t('View') }}</span>
          </b-link>
          <b-link class="border-bottom"
                  @click="$emit('addTime')">
            <icon icon="calendar"/>
            <span class="ml-75">{{ $t('AddTime') }}</span>
          </b-link>

        <li class="v-context__sub">
          <a>
            <icon icon="stopwatch"/>
            <span class="ml-75">{{ $t('ModifyDeadline') }}</span>
          </a>
          <ul class="v-context">
            <li>
              <icon icon="stopwatch"/>
              <span class="ml-75">{{ $t('ModifyDeadline') }}</span>
            </li>
            <li>
              <field-date
                  @input="updateDeadLine"
                  :model.sync="task.deadline"
                  :inline="true"
              />
            </li>
          </ul>
        </li>
        <li>
          <b-link class="border-bottom"
                  @click="removeDeadline">
            <icon icon="stopwatch"/>
            <span class="ml-75">{{ $t('RemoveDeadline') }}</span>
          </b-link>
        </li>
      </vue-context>

    </b-col>
  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../utils/filter'

import VueContext from 'vue-context'
import FieldDate from '@/components/input/Date'
import moment from 'moment'
import ButtonView from '@/components/button/View'
import ButtonDelete from '@/components/button/Delete'

export default {
  components: {
    VueContext,
    FieldDate,
    ButtonView,
    ButtonDelete
  },
  props: {
    task: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      required: true
    },
    isNew: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const taskHover = ref(false)
    const radioHover = ref(false)
    const contextMenu = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const toggleTaskStatus = () => {
      if (props.task.isDone == true) {
        props.task.isDone = false
      } else {
        props.task.isDone = true
      }
      emit('submitTask')
    }
    const updateTitle = () => {
      console.log("0")
      // emit('updateTask')
      emit('submitTask')
    }

    const updateDeadLine = () => {
      emit('submitTask')
      contextMenu.value.close()
    }

    const removeDeadline = () => {
      props.task.deadline = null
      emit('submitTask')
      // contextMenu.value.close()
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      moment,
      capitalize,

      // Data
      taskHover,
      radioHover,
      contextMenu,

      // Computed

      // Methods
      toggleTaskStatus,
      updateTitle,
      updateDeadLine,
      removeDeadline
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    // task: function (val) {
    //   console.log(val)
    // }
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/vue/libs/vue-context.scss';
</style>