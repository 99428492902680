<template>
  <b-list-group-item
      @mouseover="isDeadlineHover = true"
      @mouseleave="isDeadlineHover = false"
      class="cursor-pointer"
  >

    <div
        class="d-flex"
        @click="showForm=!showForm"
    >
      <div class="mr-1 my-auto">
        <icon icon="stopwatch"/>
      </div>
      <div class="w-100 my-auto">
        <span v-if="task.deadline == null"
              class="w-100">{{ capitalize($t('deadline')) }}
        </span>
        <span v-else
              class="w-100">
          {{ capitalize($t('deadline:', { deadline: moment(task.deadline).format('L') })) }}
        </span>
      </div>
      <!--          <div v-if="task.deadline != null" class="w-100">AddDeadline</div>-->
      <!--          <div v-else class="w-100">-->
      <!--            {{ capitalize($t('deadline:', { deadline: moment(task.deadline).format('L') })) }}-->
      <!--          </div>-->
      <div v-if="task.deadline != null && isDeadlineHover"
           class="my-auto">
        <button-remove
            :withIcon="true"
            :withText="false"
            size="sm"
            @click.native.stop="removeDeadline"
        />
      </div>
    </div>

    <transition name="fade">
      <div v-if="showForm" class="mt-1">
        <b-row>
          <b-col cols="12">
            <field-date
                @input="showForm=false"
                :model.sync="task.deadline"
                :inline="true"
                class="w-100"
            />
          </b-col>
        </b-row>
      </div>
    </transition>


  </b-list-group-item>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../../utils/filter'

import moment from 'moment'
import ButtonRemove from '@/components/button/Remove'
import FieldDate from '@/components/input/Date'

export default {
  components: {
    ButtonRemove,
    FieldDate
  },
  props: {
    task: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isDeadlineHover = ref(false)
    const showForm = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const removeDeadline = () => {
      props.task.repetition = null
      props.task.deadline = null
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      moment,

      // Data
      isDeadlineHover,
      showForm,

      // Computed

      // Methods
      removeDeadline,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>