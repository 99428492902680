<template>
  <b-list-group-item
      @mouseover="isRepetitionHover = true"
      @mouseleave="isRepetitionHover = false"
      class="cursor-pointer"
  >
    <div
        class="d-flex"
        @click="showForm=!showForm"
    >
      <div class="mr-1 my-auto">
        <icon icon="redo"/>
      </div>
      <div class="w-100 my-auto"
           :key="componentKey">
        <span v-if="task.repetition == null || task.repetition._display == null "
              class="w-100">{{ capitalize($t('repetition')) }}
        </span>
        <span v-else
              v-html="capitalize($t(task.repetition._display))"
              class="w-100">
        </span>
      </div>
      <!--          <div v-if="task.deadline != null" class="w-100">AddDeadline</div>-->
      <!--          <div v-else class="w-100">-->
      <!--            {{ capitalize($t('deadline:', { deadline: moment(task.deadline).format('L') })) }}-->
      <!--          </div>-->
      <div v-if="task.repetition != null && isRepetitionHover"
           class="my-auto">
        <button-remove
            :withIcon="true"
            :withText="false"
            size="sm"
            @click.native.stop="task.repetition = null"
        />
      </div>
    </div>

    <transition name="fade">
      <div v-if="showForm"
           class="mt-1">
        <b-row>
          <b-col cols="12">
            <form-repetition
                :repetition="task.repetition"
                @updateDisplay="updateDisplay"
            />
          </b-col>
        </b-row>
      </div>
    </transition>
  </b-list-group-item>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../../utils/filter'
import { days } from '../../../../utils/utils'

import ButtonRemove from '@/components/button/Remove'
import FormRepetition from '@/components/form/Repetition'
import moment from 'moment'

export default {
  components: {
    ButtonRemove,
    FormRepetition
  },
  props: {
    task: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isRepetitionHover = ref(false)
    const showForm = ref(false)
    const componentKey = ref(0)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(showForm, val => {
      if (val) {
        if (props.task.repetition == null) {
          props.task.repetition = {}
        }
      } else {
        if (
            props.task.repetition != null && (
                !('period' in props.task.repetition) || props.task.repetition.period == null
            )
        ) {
          props.task.repetition = null
        }
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const updateDisplay = (l) => {
      // console.log(props.task)
      if (props.task.repetition != null) {
        updateDeadline()
      }

      componentKey.value++
    }

    const updateDeadline = () => {
      let today = moment()

      if (props.task.repetition.period == 'days') {
        props.task.deadline = today.add(parseInt(props.task.repetition.quantity) - 1, 'days').format('YYYY-MM-DD')
      } else if (props.task.repetition.period == 'weeks') {
        if (props.task.repetition.days.length) {
          // Selected days
          let todayDayInt = (parseInt(today.format('d')) - 1) // Sunday = 0
          if (todayDayInt == -1) {
            todayDayInt = 6
          }
          let todayDay = days[todayDayInt]

          if (props.task.repetition.days.includes(todayDay)) {
            props.task.deadline = today.format('YYYY-MM-DD')
          } else {
            let nextConcernedDay = props.task.repetition.days.find(d => days.indexOf(d) > todayDayInt)
            if (nextConcernedDay == undefined) {
              nextConcernedDay = props.task.repetition.days[0]
            }

            let nextConcernedDayPosition = days.indexOf(nextConcernedDay)
            if (nextConcernedDayPosition <= todayDayInt) {
              nextConcernedDayPosition += 7
            }
            let nextConcernedDate = today.weekday(nextConcernedDayPosition)
            nextConcernedDate.add(parseInt(props.task.repetition.quantity)-1, 'weeks')

            props.task.deadline = nextConcernedDate.format('YYYY-MM-DD')
          }

        } else {
          // day not selected

        }
      }
    }
    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      isRepetitionHover,
      showForm,
      componentKey,

      // Computed

      // Methods
      updateDisplay,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>