<template>
  <b-row>
    <!--          Place-->
    <b-col md="6">
      <field-address
          :name="'place'"
          :placeholder="capitalize($t('place'))"
          :model.sync="event.place"
          :disabled="disabled"
      />
    </b-col>

  </b-row>
</template>

<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { capitalize } from '@/utils/filter'

import FieldAddress from '@/components/input/Address'

export default {
  components: {
    FieldAddress
  },
  props: {
    event: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: true
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>