<template>
  <div>
    <b-row>
      <b-col cols="12">
        <calendar
            ref="calendar"
            :withToolbar="withToolbar"
            @dateClick="dateClick"
            @eventClick="eventClick"
            @addEvent="addEvent"
            @updateEventDates="updateEventDates"
        />
      </b-col>
    </b-row>


    <!-- modal event-->
    <modal-event
        :event.sync="currentEvent"
        :title="eventModalTitle"
        :isOpen.sync="eventModalShow"
        @submitValidatedEvent="submitValidatedEventLocal"
        @deleteEvent="deleteEventAlert(currentEvent)"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { mixinCalendar } from './mixinCalendar'

export default {
  components: {},
  mixins: [mixinCalendar],
  props: {
    withToolbar: {
      type: Boolean,
      default: true
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>