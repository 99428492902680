<template>
  <div>
    <b-row>

      <!--          Title-->
      <b-col cols="8">
        <field
            :name="'title'"
            :rules="event.type != 'holiday'?'required':''"
            :model.sync="event.title"
            :disabled="isDisabled"
        />
      </b-col>

      <!--          All day-->
      <b-col v-if="event.type != 'holiday'"
             :cols="isEdit?3:4">
        <span>{{ capitalize($t('allDay')) }}</span>
        <b-form-checkbox
            v-model="event.isAllDay"
            class="custom-control-primary"
            switch
            :disabled="isDisabled"
        >
          <!--      <span class="switch-icon-left">-->
          <!--        <icon icon="building"/>-->
          <!--      </span>-->
          <!--      <span class="switch-icon-right">-->
          <!--        <icon icon="user"/>-->
          <!--      </span>-->
        </b-form-checkbox>
      </b-col>

      <!--          Delete-->
      <b-col
          v-if="isEdit"
          cols="1"
          class="my-auto cursor-pointer"
          @mouseover="isDeleteIconHovered = true"
          @mouseleave="isDeleteIconHovered = false"
          :class="{'text-danger': isDeleteIconHovered}"
          @click="isDisabled?false:$emit('deleteEvent')"
      >
        <icon
            icon="trash-alt"
            :disabled="isDisabled"
        />
      </b-col>

      <!-- Dates -->
      <b-col cols="12">

        <!-- Holiday -->
        <b-row v-if="event.type == 'holiday'">

          <!-- Start date -->
          <b-col md="3">
            <field-date
                :name="'startDate'"
                :rules="'required'"
                :model.sync="event.startDate"
                :enableTime="false"
                :disabled="isDisabled"
                @input="dateChange"
            />
          </b-col>

          <!-- Morning / afternoon -->
          <b-col md="3">
            <field-select
                :name="'startDate'"
                :options="morningAfternoonArray"
                label="display"
                reduce="value"
                :rules="'required'"
                :model.sync="startMorningAfternoon"
                :disabled="isDisabled"
                @input="dateChange"
            />
          </b-col>

          <!-- End date-->
          <b-col md="3">
            <field-date
                :rules="'required'"
                :name="'endDate'"
                :model.sync="event.endDate"
                :enableTime="false"
                :minDate="event.startDate"
                :disabled="isDisabled"
                @input="dateChange"
            />
          </b-col>

          <!-- Morning / afternoon -->
          <b-col md="3">
            <field-select
                :name="'endDate'"
                :options="morningAfternoonArray"
                label="display"
                reduce="value"
                :rules="'required'"
                :model.sync="endMorningAfternoon"
                @input="dateChange"
                :key="componentKey"
                :disabled="isEndMorningAfternoonDisabled || isDisabled"
            />
          </b-col>

        </b-row>

        <!-- Not holiday -->
        <b-row v-else>
          <!--          Start date-->
          <b-col md="6">
            <field-date
                :name="'startDate'"
                :rules="'required'"
                :model.sync="event.startDate"
                :enableTime="event.isAllDay?false:true"
                :disabled="isDisabled"
            />
          </b-col>

          <!--          End date-->
          <b-col md="6">
            <field-date
                v-if="!event.isAllDay"
                :rules="'required'"
                :name="'endDate'"
                :model.sync="event.endDate"
                :enableTime="event.isAllDay?false:true"
                :disabled="isDisabled"
                :minDate="event.startDate"
            />
          </b-col>
        </b-row>
      </b-col>

      <!--          Type-->
      <b-col md="6"
             class="mb-1"
             :key="componentKey">
        <div class="mb-25">{{ capitalize($t('type')) }} :</div>

        <div class="d-flex">
          <div
              @click="isDisabled?false:changeType('default')"
              class="cursor-pointer pr-2"
              :class="{'text-primary': event.type == 'default'}"
              v-b-tooltip.hover.bottom="capitalize($t('work'))"
          >
            <icon
                icon="user-cog"
                size="2x"
                :disabled="isDisabled"
            />
          </div>

          <div
              @click="isDisabled?false:changeType('visio')"
              class="cursor-pointer px-2"
              :class="{'text-primary': event.type == 'visio'}"
              v-b-tooltip.hover.bottom="capitalize($t('visio'))"
          >
            <icon
                icon="headset"
                size="2x"
                :disabled="isDisabled"
            />
          </div>

          <div
              @click="isDisabled?false:changeType('site')"
              class="cursor-pointer px-2"
              :class="{'text-primary': event.type == 'site'}"
              v-b-tooltip.hover.bottom="capitalize($t('site'))"
          >
            <icon
                icon="briefcase"
                size="2x"
                :disabled="isDisabled"
            />
          </div>

          <div
              @click="isDisabled?false:changeType('trip')"
              class="cursor-pointer px-2"
              :class="{'text-primary': event.type == 'trip'}"
              v-b-tooltip.hover.bottom="capitalize($t('trip'))"
          >
            <icon
                icon="suitcase-rolling"
                size="2x"
                :disabled="isDisabled"
            />
          </div>

          <div
              @click="isDisabled?false:changeType('holiday')"
              class="cursor-pointer px-2"
              :class="{'text-primary': event.type == 'holiday'}"
              v-b-tooltip.hover.bottom="capitalize($t('holiday'))"
          >
            <icon
                icon="umbrella-beach"
                size="2x"
                :disabled="isDisabled"
            />
          </div>


        </div>

      </b-col>

      <!--      Members-->
      <b-col md="6">
        <field-employee
            :model.sync="event.additionalMembers"
            :multiple="true"
            :name="capitalize($t('additionalMembers'))"
        />

      </b-col>

    </b-row>

    <div
        v-if="['visio', 'site', 'trip'].includes(event.type)"
        class="w-100"
        style="border-top: 1px solid rgba(34,41,47,.05);"
    >

      <form-call
          v-if="event.type == 'call'"
          :event="event"
          :disabled="isDisabled"
          class="mt-1"
      />
      <form-visio
          v-if="event.type == 'visio'"
          :event="event"
          :disabled="isDisabled"
          class="mt-1"
      />
      <form-site
          v-if="event.type == 'site'"
          :event="event"
          :disabled="isDisabled"
          class="mt-1"
      />
      <form-trip
          v-if="event.type == 'trip'"
          :event="event"
          :disabled="isDisabled"
          class="mt-1"
      />
      <form-holiday
          v-if="event.type == 'holiday'"
          :event="event"
          :disabled="isDisabled"
          class="mt-1"
      />


    </div>

    <task-event-tabs :task-event="event"/>

  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { capitalize } from '@/utils/filter'
import { getUserData } from '../../../utils/utils'

import Field from '@/components/input/Input'
import FieldDate from '@/components/input/Date'
import FieldSelect from '@/components/input/Select'
import FormCall from './components/_call'
import FormHoliday from './components/_holiday'
import FormSite from './components/_site'
import FormTrip from './components/_trip'
import FormVisio from './components/_visio'
import i18n from '@/libs/i18n'
import moment from 'moment'
import TaskEventTabs from '../taskEventTabs/TaskEventTabs'
import FieldEmployee from '../../input/Employee'

export default {
  components: {
    Field,
    FieldDate,
    FieldSelect,
    FormCall,
    FormHoliday,
    FormSite,
    FormTrip,
    FormVisio,
    TaskEventTabs,
    FieldEmployee
  },
  props: {
    event: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    // const eventLocal = ref({})
    const isDeleteIconHovered = ref(false)
    const morningAfternoonArray = ref([{
      value: 'morning',
      display: capitalize(i18n.t('morning'))
    }, {
      value: 'afternoon',
      display: capitalize(i18n.t('afternoon'))
    }])
    const startMorningAfternoon = ref('morning')
    const endMorningAfternoon = ref('morning')
    const isEndMorningAfternoonDisabled = ref(false)
    const componentKey = ref(0)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isEdit = computed(() => {
      return ('id' in props.event)
    })

    const isDisabled = computed(() => {
      if ('logs' in props.event) {
        return props.event.isValidated || (getUserData().id != props.event.logs[0].by.id)
      } else {
        return false
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(startMorningAfternoon, (val) => {
      changeDate(val == 'morning', true)

      // if (val == 'morning') {
      //   let startDate = moment(props.event.startDate)
      //   startDate.set('hour', 8)
      //   startDate.set('minute', 0)
      //   props.event.startDate = startDate.format('YYYY-MM-DD HH:mm')
      // } else {
      //   let startDate = moment(props.event.startDate)
      //   startDate.set('hour', 14)
      //   startDate.set('minute', 0)
      //   props.event.startDate = startDate.format('YYYY-MM-DD HH:mm')
      // }
    })

    watch(endMorningAfternoon, (val) => {
      changeDate(val == 'morning', false)
      // if (val == 'morning') {
      //   let endDate = moment(props.event.endDate)
      //   endDate.set('hour', 12)
      //   endDate.set('minute', 0)
      //   props.event.endDate = endDate.format('YYYY-MM-DD HH:mm')
      // } else {
      //   let endDate = moment(props.event.endDate)
      //   endDate.set('hour', 18)
      //   endDate.set('minute', 0)
      //   props.event.endDate = endDate.format('YYYY-MM-DD HH:mm')
      // }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const changeType = (newType) => {
      props.event.type = newType

      // props.event.visioLink = null
      // props.event.place = null
      // props.event.startPlace = null
      // props.event.endPlace = null

      if (newType == 'holiday') {
        changeDate(startMorningAfternoon.value == 'morning', true)
        changeDate(endMorningAfternoon.value == 'morning', false)
        // changeDate(false, true)
        // changeDate(true, false)
        // changeDate(false, false)
      }

      componentKey.value++
    }

    const changeDate = (isMorning, isStart) => {
      if (isMorning) {
        if (isStart) {
          let startDate = moment(props.event.startDate)
          startDate.set('hour', 8)
          startDate.set('minute', 0)
          props.event.startDate = startDate.format('YYYY-MM-DD HH:mm')
        } else {
          let endDate = moment(props.event.endDate)
          endDate.set('hour', 12)
          endDate.set('minute', 0)
          props.event.endDate = endDate.format('YYYY-MM-DD HH:mm')
        }
      } else {
        if (isStart) {
          let startDate = moment(props.event.startDate)
          startDate.set('hour', 14)
          startDate.set('minute', 0)
          props.event.startDate = startDate.format('YYYY-MM-DD HH:mm')
        } else {
          let endDate = moment(props.event.endDate)
          endDate.set('hour', 18)
          endDate.set('minute', 0)
          props.event.endDate = endDate.format('YYYY-MM-DD HH:mm')
        }
      }
    }

    const dateChange = () => {
      isEndMorningAfternoonDisabled.value = false
      if (
          moment(props.event.endDate).format('YYYY-MM-DD') == moment(props.event.startDate).format('YYYY-MM-DD') &&
          startMorningAfternoon.value == 'afternoon'
      ) {
        isEndMorningAfternoonDisabled.value = true
        endMorningAfternoon.value = 'afternoon'
        componentKey.value++
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      isDeleteIconHovered,
      morningAfternoonArray,
      startMorningAfternoon,
      endMorningAfternoon,
      isEndMorningAfternoonDisabled,
      componentKey,

      // Computed
      isEdit,
      isDisabled,

      // Methods
      changeType,
      dateChange,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>