<template>
  <b-card
      no-body
      :class="{'open': visible}"
  >
    <b-card-header

        :class="{'collapsed': !visible}"
        :aria-expanded="visible ? 'true' : 'false'"
        :aria-controls="collapseItemID"
        role="tab"
        data-toggle="collapse"
        @click="updateVisible(!visible)"
    >
      <slot name="header">
        <span class="lead collapse-title">{{ title }}</span>
      </slot>
    </b-card-header>

    <b-collapse
        :id="collapseItemID"
        v-model="visible"
        :accordion="accordion"
        role="tabpanel"
    >
      <b-card-body>
        <slot/>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import { ref, computed, watch, getCurrentInstance } from '@vue/composition-api'

export default {
  components: {},
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const instance = getCurrentInstance()
    const visible = ref(props.isVisible)
    const collapseItemID = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const accordion = computed(() => {
      return instance.parent.accordion ? `accordion-${instance.parent.collapseID.toString()}` : null
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const updateVisible = (val) => {
      visible.value = val
      emit('visible', val)
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    collapseItemID.value = (Math.floor(Math.random() * 100)).toString()

    return {
      // Components

      // Data
      visible,
      collapseItemID,

      // Computed
      accordion,

      // Methods
      updateVisible,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>