<template>
  <div class="d-flex justify-content-between">
    <field-select
        :model.sync="selectedCalendars"
        :multiple="true"
        :options="calendars"
        :label="'name'"
        :reduce="'name'"
        :key="componentKey"
    >
      <template #customList="{option}">
        {{ capitalize($tc(option.name,2)) }}
      </template>

    </field-select>

    <div class="w-50"></div>

    <div>
      <button-add
          @click="$emit('addEvent')"
          :text="$t('AddEvent')"
      />
    </div>


  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../utils/filter'
import { useCalendar } from '../useCalendar'

import FieldSelect from '@/components/input/Select'
import ButtonAdd from '@/components/button/Add'

export default {
  components: {
    FieldSelect,
    ButtonAdd
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const componentKey = ref(0)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const {
      calendars,
      selectedCalendars,
    } = useCalendar()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(calendars, val => {
      componentKey.value++
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      componentKey,

      // Computed
      calendars,
      selectedCalendars,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>