import { ref, computed, watch, onMounted, reactive } from '@vue/composition-api'
import { getCurrentInstance } from '@vue/composition-api'
import { dom } from '@fortawesome/fontawesome-svg-core'
import { getRoute, getEventIcon } from '@/utils/utils'
import { capitalize } from '../../utils/filter'

import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import i18n from '@/libs/i18n'
import store from '@/store'
import moment from 'moment'
import { eventToCalendarEvent, getEventIcons } from '../../utils/utils'

export const useCalendar = () => {
  dom.watch()

  const fetchEvents = (fetchInfo, successCallback) => {
    if (!fetchInfo) return

    store.getters['calendar/getEvents'].then(result => {
      successCallback(result)
    })
  }

  // ------------------------------------------------
  // Data
  // ------------------------------------------------
  let calendarApi = null
  const refCalendar = ref(null)
  const { emit } = getCurrentInstance()

  const blankEvent = {
    title: '',
    start: null,
    end: null,
    allDay: false,
    url: '',
    extendedProps: {
      color: 'primary',
      icon: 'plane',
      entity: null
    },
  }
  const event = ref(null)

  const calendarOptions = ref({
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
    initialView: 'timeGridWeek',
    headerToolbar: {
      start: 'sidebarToggle, prev, today, next, title',
      end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
    },
    buttonText: {
      today: i18n.t('today'),
      month: i18n.t('month'),
      week: i18n.t('week'),
      day: i18n.t('day'),
      list: i18n.t('list')
    },
    allDayText: i18n.t('allDay'),
    locale: 'fr',
    firstDay: 1,
    // timeZone: 'UTC',
    nowIndicator: true,
    selectable: true,
    editable: true,
    eventResizableFromStart: true,
    dragScroll: true,
    dayMaxEvents: 2,
    navLinks: true,
    events: fetchEvents,

    moreLinkContent (arg) {
      return { html: arg.shortText + ' ' + i18n.tc('more', arg.num) }
    },

    eventClassNames ({ event: calendarEvent }) {
      // eslint-disable-next-line no-underscore-dangle
      // const colorName = calendars.value.find(cc => cc.name == calendarEvent._def.extendedProps.calendar).color
      // const colorName = calendarsColors.value.find(cc => cc.label == calendarEvent._def.extendedProps.calendar).color
      const colorName = calendarEvent._def.extendedProps.color

      // return 'bg-light-' + calendarEvent._def.extendedProps.color
      return `bg-light-${colorName}`
    },
    eventContent (arg) {
      // console.log(arg)

      if (
        'icons' in arg.event.extendedProps &&
        arg.event.extendedProps.icons.length != 0
      ) {
        let mainFrame = document.createElement('div')
        mainFrame.classList.add('fc-event-main-frame')

        let eventTime = document.createElement('div')
        eventTime.classList.add('fc-event-time')
        eventTime.innerHTML = arg.timeText
        mainFrame.appendChild(eventTime)

        let eventTitleContainer = document.createElement('div')
        eventTitleContainer.classList.add('fc-event-title-container')

        let eventTitleSticky = document.createElement('div')
        eventTitleSticky.classList.add('fc-event-title')
        eventTitleSticky.classList.add('fc-sticky')

        arg.event.extendedProps.icons.forEach(icon => {
          let eventIcon = document.createElement('i')
          eventIcon.classList.add('fa')
          eventIcon.classList.add('fa-' + icon)
          eventIcon.classList.add('mr-25')
          if (['exclamation-triangle', 'stopwatch'].includes(icon)) {
            eventIcon.classList.add('text-warning')
          }

          eventTitleSticky.appendChild(eventIcon)
        })

        let eventTitle = document.createElement('span')
        eventTitle.classList.add('ml-25')
        eventTitle.innerHTML = arg.event.title
        eventTitleSticky.appendChild(eventTitle)

        eventTitleContainer.appendChild(eventTitleSticky)
        mainFrame.appendChild(eventTitleContainer)

        let arrayOfDomNodes = [mainFrame]
        return { domNodes: arrayOfDomNodes }
      }

    },
    select (selectionInfo) {
      // console.log(selectionInfo)
      let start = new Date(JSON.parse(JSON.stringify(selectionInfo.start)))
      let end = new Date(JSON.parse(JSON.stringify(selectionInfo.end)))
      let minutes = (end - start) / 60000

      if (minutes != 30 && selectionInfo.allDay == false) {
        event.value = JSON.parse(JSON.stringify(Object.assign(event.value, {
          allDay: selectionInfo.allDay,
        })))
        event.value.start = start
        event.value.end = end

        emit('dateClick', event.value)
      }

    },
    eventClick ({ event: clickedEvent }) {
      // console.log(grabEventDataFromEventApi(clickedEvent))
      emit('eventClick', grabEventDataFromEventApi(clickedEvent))
    },
    dateClick (info) {
      resetEvent()

      // console.log(info)
      let start = new Date(JSON.parse(JSON.stringify(info.date)))

      event.value = JSON.parse(JSON.stringify(Object.assign(event.value, {
        allDay: info.allDay,
      })))
      event.value.start = start
      event.value.start.setMinutes(start.getMinutes() + 0)

      if (info.allDay == false) {
        let end = new Date(JSON.parse(JSON.stringify(info.date)))
        event.value.end = end
        event.value.end.setMinutes(end.getMinutes() + 30)
      }

      emit('dateClick', event.value)
    },
    eventDrop ({ event: droppedEvent }) {
      updateEvent(grabEventDataFromEventApi(droppedEvent))
    },
    eventResize ({ event: resizedEvent }) {
      // console.log("eventResize")
      updateEvent(grabEventDataFromEventApi(resizedEvent))
    },
  })

  const isEventHandlerSidebarActive = ref(false)

  const isCalendarOverlaySidebarActive = ref(false)

  // ------------------------------------------------
  // Computed
  // ------------------------------------------------
  const calendars = computed(() => {
    return store.getters['calendar/getCalendars']
  })

  const selectedCalendars = computed({
    get: () => store.getters['calendar/getSelectedCalendars'],
    set: val => {
      store.commit('calendar/SET_SELECTED_CALENDARS', val)
    }
  })

  // ------------------------------------------------
  // Watch
  // ------------------------------------------------
  watch(refCalendar, (val) => {
    if (val != null) {
      calendarApi = refCalendar.value.getApi()
    }
  })

  watch(selectedCalendars, (val) => {
    refetchEvents()
  })

  // ------------------------------------------------
  // Methods
  // ------------------------------------------------

  const grabEventDataFromEventApi = eventApi => {
    const {
      id,
      title,
      start,
      end,
      // eslint-disable-next-line object-curly-newline
      extendedProps: { color, entity },
      allDay,
    } = eventApi

    return {
      id,
      title,
      start,
      end,
      extendedProps: {
        color,
        entity,
      },
      allDay,
    }
  }

  const addEvent = eventData => {
    // eventsLocal.value.push(eventData)
  }

  const updateEvent = eventData => {
    // console.log(eventData)
    if (getRoute().name == 'Workflow view') {
      eventData.extendedProps.entity.workflow = parseInt(getRoute().params.workflowId)
    }

    emit('updateEventDates', eventData.extendedProps.entity, eventData.start, eventData.end)
  }

  const resetEvent = () => {
    event.value = JSON.parse(JSON.stringify(blankEvent))
  }

  const refetchEvents = () => {
    if (calendarApi != null) {
      // console.log("refetch")
      calendarApi.refetchEvents()
    }
  }

  const refetchEventAfterUpdate = (updatedEvent) => {
    if (calendarApi != null) {
      // console.log("refetch update")

      const existingEvent = calendarApi.getEventById(updatedEvent.id)

      existingEvent.setProp('title', updatedEvent.title)
      existingEvent.setProp('editable', (!updatedEvent.isValidated && updatedEvent.type != 'holiday'))
      existingEvent.setDates(updatedEvent.startDate, updatedEvent.endDate, { allDay: updatedEvent.allDay })

      existingEvent.setExtendedProp('entity', updatedEvent)
      existingEvent.setExtendedProp('icons', getEventIcons(updatedEvent))
      // existingEvent.setExtendedProp('secondaryIcon', (updatedEvent.type == 'holiday' && updatedEvent.isValidated == null) ? 'stopwatch' : null)

    }
  }

  const removeCalendarEvent = (eventId) => {
    if (calendarApi != null) {
      calendarApi.getEventById(eventId).remove()
    }
  }

  // ------------------------------------------------
  // Mounted
  // ------------------------------------------------

  fetchEvents()
  resetEvent()
  return {
    // Data
    refCalendar,
    event,
    calendarOptions,
    isEventHandlerSidebarActive,
    isCalendarOverlaySidebarActive,

    // Computed
    calendars,
    selectedCalendars,

    // Methods
    addEvent,
    updateEvent,
    refetchEvents,
    refetchEventAfterUpdate,
    removeCalendarEvent
  }
}