<template>
  <div>
    <div class="w-100 d-flex justify-content-center inputDays">
      <button
          v-for="day in localDays"
          @click.stop.prevent="day.isSelected=!day.isSelected"
          class="inputDay"
          :class="{'active': day.isSelected}"
      >
        {{ capitalize($t(day.value).substr(0, 2)) }}
      </button>

    </div>

    <field-input
        :model="localDays.filter(d => d.isSelected).map(d => d.value).join(', ')"
        :name="$t('days')"
        rules="required"
        :displayErrorOnly="true"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../utils/filter'

import FieldInput from '@/components/input/Input'

export default {
  components: {
    FieldInput
  },
  props: {
    days: {
      type: Array,
      default: () => []
    },
    isRequired: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const localDays = ref([
      {
        value: 'monday',
        isSelected: props.days.includes('monday')
      },
      {
        value: 'tuesday',
        isSelected: props.days.includes('tuesday')
      },
      {
        value: 'wednesday',
        isSelected: props.days.includes('wednesday')
      },
      {
        value: 'thursday',
        isSelected: props.days.includes('thursday')
      },
      {
        value: 'friday',
        isSelected: props.days.includes('friday')
      },
      {
        value: 'saturday',
        isSelected: props.days.includes('saturday')
      },
      {
        value: 'sunday',
        isSelected: props.days.includes('sunday')
      },
    ])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(() => localDays, (val) => {
          // console.log(val.value.filter(d => d.isSelected).map(d => d.value))
          emit('update:days', val.value.filter(d => d.isSelected).map(d => d.value))
        },
        { deep: true }
    )

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      localDays,

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
.inputDays {
  .inputDay {
    border-left: 0px;
    border-right: 0px;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    text-align: center;
    height: 36px;
    width: 100%;

    &:first-child {
      border-left: 1px solid #eaeaea;
    }

    &:last-child {
      border-right: 1px solid #eaeaea;
    }

    &:active {
      color: white;
      background-color: var(--primary);
    }
  }

  .active {
    color: white;
    background-color: var(--primary);
  }
}
</style>