<template>
  <b-list-group-item
      @mouseover="isWorkflowHover = true"
      @mouseleave="isWorkflowHover = false"
      class="cursor-pointer"
  >

    <div
        class="d-flex"
        @click="showForm=!showForm"
    >
      <div class="mr-1 my-auto">
        <icon icon="paper-plane"/>
      </div>
      <div class="w-100 my-auto">
        <span v-if="task.workflow == null"
              class="w-100">{{$t('AddWorkflow')}}
        </span>
        <span v-else
              class="w-100">
          {{ capitalize($t('workflow:')) }} {{ task.workflow.opportunity }}
        </span>
      </div>
      <!--          <div v-if="task.workflow != null" class="w-100">AddWorkflow</div>-->
      <!--          <div v-else class="w-100">-->
      <!--            {{ capitalize($t('workflow:', { workflow: moment(task.workflow).format('L') })) }}-->
      <!--          </div>-->
      <div v-if="task.workflow != null && isWorkflowHover"
           class="my-auto">
        <button-remove
            :withIcon="true"
            :withText="false"
            size="sm"
            @click.native.stop="workflowLocal=null"
        />
      </div>
    </div>

    <transition name="fade">
      <div v-if="showForm"
           class="mt-1">
        <b-row>
          <b-col cols="12">
            <field-select
                :model.sync="workflowLocal"
                :name="'workflow'"
                :options="workflows"
                :label="'opportunity'"
                class="w-100"
            />
          </b-col>
        </b-row>
      </div>
    </transition>


  </b-list-group-item>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../../utils/filter'
import useAPI from '../../../../utils/useAPI'

import moment from 'moment'
import ButtonRemove from '@/components/button/Remove'
import FieldSelect from '@/components/input/Select'
import { isObject } from '../../../../utils/utils'

export default {
  components: {
    ButtonRemove,
    FieldSelect
  },
  props: {
    task: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isWorkflowHover = ref(false)
    const showForm = ref(false)
    const workflowLocal = ref(props.task.workflow)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { workflows } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(workflowLocal, (val) => {
      if (!isObject(val)) {
        props.task.workflow = workflows.value.find(w => w.id == val)
      } else {
        props.task.workflow = val
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      moment,

      // Data
      isWorkflowHover,
      showForm,
      workflowLocal,

      // Computed
      workflows,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>