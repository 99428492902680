<template>
  <div class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1">
    <div class="p-2">
      <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          aria-controls="sidebar-add-new-event"
          :aria-expanded="String(isEventHandlerSidebarActive)"
          variant="primary"
          block
          @click="$emit('update:isEventHandlerSidebarActive', true)"
      >
        Add Event
      </b-button>

      <div class="mt-3">
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">{{ $tc('calendar', 2) }}</span>
        </h5>
        <b-form-checkbox
            v-model="checkAll"
            class="mb-1"
        >
          View All
        </b-form-checkbox>
        <b-form-group>
          <b-form-checkbox-group
              v-model="selectedCalendars"
              name="event-filter"
              stacked
          >
            <b-form-checkbox
                v-for="item in calendars"
                :key="item.name"
                name="event-filter"
                :value="item.name"
                class="mb-1"
                :class="`custom-control-${item.color}`"
            >
              {{ capitalize($tc(item.name, 2)) }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </div>

    </div>
    <b-img
        :src="require('@/assets/images/pages/calendar-illustration.png')"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { useCalendar } from '../useCalendar'
import { capitalize } from '../../../utils/filter'

import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {},
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      require: true,
    },
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const {
      calendars,
      selectedCalendars,
    } = useCalendar()

    const checkAll = computed({
      get: () => selectedCalendars.value.length === calendars.value.length,
      set: val => {
        if (val) {
          selectedCalendars.value = calendars.value.map(i => i.name)
        } else if (selectedCalendars.value.length === calendars.value.length) {
          selectedCalendars.value = []
        }
      },
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------

    return {
      // Components
      capitalize,

      // Data

      // Computed
      calendars,
      selectedCalendars,
      checkAll,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>