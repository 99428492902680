<template>
  <b-modal
      id="1"
      :title="title"
      v-model="isOpenLocal"
      size="lg"
      scrollable
      hide-footer
      @hide="tryCloseEventModal"
  >
    <b-card>
      <validation-observer #default="{handleSubmit}">
        <b-form
            @keydown.enter.stop.prevent=""
            @submit.prevent="handleSubmit(submitValidatedEventLocal)">
          <form-event :event="eventLocal"
                      @deleteEvent="$emit('deleteEvent')"/>
          <button ref="submitEventFormRef"
                  class="d-none"></button>
        </b-form>
      </validation-observer>
    </b-card>
  </b-modal>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'

import FormEvent from '@/components/form/event/Event'

export default {
  components: {
    ValidationObserver,
    FormEvent
  },
  props: {
    event: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isOpenLocal = ref(false)
    const eventLocal = ref(props.event)
    const submitEventFormRef = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(isOpenLocal, (val) => {
      emit('update:isOpen', val)
    })
    watch(eventLocal, (val) => {
      emit('update:event', val)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const tryCloseEventModal = (modalEvent) => {
      if (modalEvent.trigger == 'backdrop') {
        // Click outside
        modalEvent.preventDefault()
        submitEventFormRef.value.click()
      }
    }

    const submitValidatedEventLocal = () => {
      emit('submitValidatedEvent')
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      isOpenLocal,
      eventLocal,
      submitEventFormRef,

      // Computed

      // Methods
      tryCloseEventModal,
      submitValidatedEventLocal
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    event: function (val) {
      this.eventLocal = val
    },
    isOpen: function (val) {
      this.isOpenLocal = val
    }
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>