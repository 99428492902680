import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { useEvents } from './useEvents'
import { capitalize } from '@/utils/filter'
import { getRoute } from '@/utils/utils'

import i18n from '@/libs/i18n'
import store from '@/store'
import router from '@/router'
import useAPI from '@/utils/useAPI'
import moment from 'moment'

import Calendar from '@/components/calendar/Calendar'
import ModalEvent from '@/components/prompt/Event'
import Vue from 'vue'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'

export const mixinCalendar = {
  components: {
    Calendar,
    ModalEvent
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const calendar = ref(null)
    const eventModalShow = ref(false)
    const eventModalTitle = ref(i18n.t('NewEvent'))
    const currentEvent = ref({})
    const submitEventFormRef = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(eventModalShow, () => {
      if ('id' in currentEvent.value) {
        eventModalTitle.value = i18n.t('EditEvent')
      }

      if (eventModalShow.value == false) {
        resetEvent()
      }
    })

    watch(currentEvent, () => {
      if (getRoute().name == 'Workflow view') {
        let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)

        if (typeof workflow != 'undefined') {
          // console.log(workflow)
          currentEvent.value.workflow = {
            id: workflow.id,
            opportunity: workflow.opportunity
          }
        }

      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      submitValidatedEvent,
      removeEvent,
    } = useEvents()

    const addEvent = () => {
      currentEvent.value = JSON.parse(JSON.stringify(store.getters['event/getEmptyEvent']))
      eventModalShow.value = true
    }

    const dateClick = (calendarEvent) => {
      populateCurrentEventFromCalendarEvent(calendarEvent)
      eventModalShow.value = true
    }

    const eventClick = (calendarEvent) => {
      currentEvent.value = JSON.parse(JSON.stringify(calendarEvent.extendedProps.entity))
      eventModalShow.value = true
    }

    const updateEventDates = (entity, newStart, newEnd) => {
      currentEvent.value = JSON.parse(JSON.stringify(entity))

      currentEvent.value.startDate = moment(newStart).format('YYYY-MM-DD HH:mm:ss')
      currentEvent.value.endDate = moment(newEnd).format('YYYY-MM-DD HH:mm:ss')
      // console.log(currentEvent.value)

      submitValidatedEventLocal()
    }

    const populateCurrentEventFromCalendarEvent = (calendarEvent) => {
      currentEvent.value.endDate = calendarEvent.end
      currentEvent.value.isAllDay = calendarEvent.allDay
      currentEvent.value.startDate = calendarEvent.start
    }

    const tryCloseEventModal = (modalEvent) => {
      if (modalEvent.trigger == 'backdrop') {
        // Click outside
        modalEvent.preventDefault()
        submitEventFormRef.value.click()
      }
    }

    const submitValidatedEventLocal = () => {
      // console.log(currentEvent.value)

      if (
        currentEvent.value.type == 'trip' &&
        (
          !('_display' in currentEvent.value.startPlace) ||
          !('_display' in currentEvent.value.endPlace)
        )
      ) {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: capitalize(i18n.t('error')),
            icon: 'times',
            text: i18n.t('Address not found'),
            variant: 'danger'
          },
        })
        // console.log("Bad addresses")
      } else {
        submitValidatedEvent(currentEvent.value)
          .then(response => {
            // console.log(calendar.value)
            if ('id' in currentEvent.value && currentEvent.value.id != null) {
              calendar.value.refetchEventAfterUpdate(currentEvent.value)
            } else {
              calendar.value.refetchEvents()
            }

            eventModalShow.value = false

            if (getRoute().name == 'Workflow view') {
              let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)
              response.data.workflow = response.data.workflow.id

              if ('id' in currentEvent.value) {
                let index = workflow.events.findIndex(i => i.id == currentEvent.value.id)
                workflow.events.splice(index, 1, JSON.parse(JSON.stringify(response.data)))
              } else {
                workflow.events.push(response.data)
              }

            }

            resetEvent()
          })
      }
    }

    const resetEvent = () => {
      currentEvent.value = JSON.parse(JSON.stringify(store.getters['event/getEmptyEvent']))
      eventModalTitle.value = i18n.t('NewEvent')
    }

    const removeEventLocal = (event) => {
      removeEvent(event)
        .then(response => {
          if (getRoute().name == 'Event view') {
            router.push({ name: 'Events' })
          } else if (getRoute().name == 'Workflow view') {
            eventModalShow.value = false

            let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)
            workflow.events = workflow.events.filter(ii => ii.id != event.id)
          }

          eventModalShow.value = false
          calendar.value.removeCalendarEvent(event.id)
        })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetEvent()

    return {
      // Components
      capitalize,

      // Data
      calendar,
      eventModalShow,
      eventModalTitle,
      currentEvent,
      submitEventFormRef,

      // Computed

      // Methods
      addEvent,
      dateClick,
      eventClick,
      updateEventDates,
      // updateEventDates,
      tryCloseEventModal,
      submitValidatedEventLocal,
      removeEventLocal

    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    deleteEventAlert (event) {
      this.$bvModal
        .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theEvent') }), {
          okVariant: 'danger',
          okTitle: this.capitalize(this.$t('delete')),
          cancelVariant: 'outline-secondary',
          cancelTitle: this.capitalize(this.$t('cancel')),
          centered: true,
        })
        .then(value => {
          if (value == true) {
            this.removeEventLocal(event)
          }
        })
    }
  },
  mounted () {
  },
  created () {
  }
}